.calc_detail_wrap {
  width: 25%;
  padding: 5px 5px 10px 5px;
}

@media (max-width: 745px) {
  .calc_detail_wrap {
    width: 50%;
    padding: 5px 5px 10px 5px;
  }
}

@media(max-width:745px) {
    .calc_detail_wrap {
        width: 50%;
        padding: 5px 5px 10px 5px;
    }
}
.stiky_btn {
  display: none;
}
@media screen and (max-width: 992px) {
  .stiky_btn {
    background: #f1f1f1ad;
    position: fixed;
    bottom: -2px;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 10;
    align-items: center;
    a {
      width: 50%;
    }
  }
}

@media(max-width: 746px) {
    .calc_detail_wrap_last {
        width: 100% !important;
        padding: 5px 5px 10px 5px;
    }
}

// @media(max-width:745px) {
//     .calc_detail_wrap_last {
//         width: 100%;
//         padding: 5px 5px 10px 5px;
//     }
// }

@media (max-width: 500px) {
  .calc_detail_wrap {
    width: 50%;
    padding: 5px 5px 10px 5px;
  }
}
